<template>
  <div>
    <img src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-09-12/14/yuelvhuiy4UPfhbB2w1631429413.jpeg" alt="金秋政策">
  </div>
</template>

<script>
export default {
  name: 'ActivityPolicy'
}
</script>

<style scoped>
img {
  max-width: 100%;
  height: auto;
}
</style>
